<template>
    <div class="rwt-about-area rn-section-gap">
        <div class="container">
            <div class="row row--30 align-items-center">
                <div class="col-lg-5">
                    <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                        <img class="w-100" src="../../../assets/images/_MyFiles/Home-About-Thumbnail.jpg" alt="About Images">
                    </div>
                </div>
                <div class="col-lg-7 mt_md--40 mt_sm--40">
                    <div class="content">
                        <div class="section-title">
                            <h4 class="subtitle" data-aos="fade-up" data-aos-delay="280">
                                <span class="theme-gradient">VisionLink About.</span>
                            </h4>
                            <h2 class="title mt--10" data-aos="fade-up" data-aos-delay="310">About Our Business.</h2>
                            <p data-aos="fade-up" data-aos-delay="310">
                                VisionLink is an innovative brand management firm based in South Africa, dedicated to propelling businesses into the digital age. Our bespoke services range from captivating website designs and innovative NFC business cards to strategic digital marketing campaigns. At VisionLink, we believe that every brand has a unique story, and our mission is to bring that story to the forefront of the digital realm, ensuring your brand not only thrives but leads in its industry.
                            </p>
                            <ul class="list-icon" data-aos="fade-up" data-aos-delay="340">
                                <li>
                                    <Icon name="check" size="20" icon-class="icon d-inline-flex align-items-center justify-content-center"/>
                                    Elevate networking with our innovative NFC business cards.
                                </li>
                                <li>
                                    <Icon name="check" size="20" icon-class="icon d-inline-flex align-items-center justify-content-center"/>
                                    Create standout graphics for events, emails, and marketing materials.
                                </li>
                                <li>
                                    <Icon name="check" size="20" icon-class="icon d-inline-flex align-items-center justify-content-center"/>
                                    Craft responsive, user-centric websites that captivate audiences.
                                </li>
                                <li>
                                    <Icon name="check" size="20" icon-class="icon d-inline-flex align-items-center justify-content-center"/>
                                    Launch strategic digital campaigns to amplify your brand's reach.
                                </li>

                            </ul>
                            <div class="read-more-btn mt--40" data-aos="fade-up" data-aos-delay="370">
                                <router-link class="btn-default btn-icon"
                                   to="/about">
                                    More About Us
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'

    export default {
        name: 'AboutTwo',
        components: {Icon}
    }
</script>