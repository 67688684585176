<template>
  <div>
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
// import ServiceWebDesign from './Service-WebDesign.vue';
import ServiceBusinessCards from './Service-BusinessCards.vue';
// import ServiceDigitalMarketing from './Service-DigitalMarketing.vue';
import ServiceOther from './Service-Other.vue';
import Error404 from './ComingSoon.vue';
import NotFound from './404.vue';

export default {
  props: ['service'],
  data() {
    return {
      components: {
        'Web-Design': Error404,
        'BusinessCards': ServiceBusinessCards,
        'Digital Marketing': Error404,
        'Other': ServiceOther
      }
    };
  },
  computed: {
    currentComponent() {
      return this.components[this.service] || NotFound;
    }
  }
};
</script>
