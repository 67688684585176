<template>

    <main class="page-wrapper">
        <Layout :topbar-style="2" :footer-style="1">
            <div class="main-content">
                <div class="sidebar">
                <!-- Add current date and time -->
                <div class="text-center text-white pt--10 date">
                    <h3 class="date-day mb-0 color-blue-gradient">{{ formattedDate }}</h3>
                    <h3 class="date-time mb-0 color-blue-gradient">{{ formattedTime }}</h3>
                </div>
                <ul class="nav flex-column">
                    <li class="nav-item">
                    <router-link
                        class="nav-link text-white"
                        active-class="text-info"
                        to="/admin/dashboard"
                        >Dashboard</router-link
                    >
                    </li>
                    <li class="nav-item">
                    <router-link
                        class="nav-link text-white"
                        active-class="text-info"
                        to="/admin/create-post"
                        >Create Blog Post</router-link
                    >
                    </li>
                    <li class="nav-item">
                    <router-link
                        class="nav-link text-white"
                        active-class="text-info"
                        to="/admin/manage-users"
                        >Manage Users</router-link
                    >
                    </li>
                    <li class="nav-item">
                    <router-link
                        class="nav-link text-white"
                        active-class="text-info"
                        to="/admin/view-analytics"
                        >View Analytics</router-link
                    >
                    </li>
                </ul>
                </div>
                <div class="content">
                <slot></slot>
                </div>
            </div>
        </Layout>

    </main>
</template>

<script>
import Layout from "./Layout";

export default {
  computed: {
    formattedTime() {
      return new Date().toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },
    formattedDate() {
      return new Date().toLocaleDateString("en-US", {
        weekday: "long",
      });
    },
  },
  name: "LayoutAdmin",
  components: { Layout },
  props: {
    logo: {
      type: String,
    },
    showTopHeader: {
      type: Boolean,
      default: true,
    },
    topbarStyle: {
      type: Number,
      default: 1,
    },
    headerClass: {
      type: String,
    },
    footerStyle: {
      type: Number,
      default: 1,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showCopyright: {
      type: Boolean,
      default: true,
    },
    showNewsletter: {
      type: Boolean,
      default: false,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>

.page-wrapper {
  display: flex;
  flex-direction: column;
}

.date{
    padding-bottom: 15px;
    border-bottom: 3px solid #00ffff;
}

.date-day {
  font-size: 40px;
}
.date-time {
  font-size: 25px;
}

.sidebar {
  width: 250px;
  background-color: rgba(0, 0, 0, 0.637);
}

.content {
  width: 100%;
  height: 100%;
  padding: 30px 30px;

}

.active{
    color: #00ffff;
}

.page-wrapper{
    background-image: url('../../assets/images/_MyFiles/AdminBG.jpg');
}

.main-content {
  display: flex;
  min-height: calc(100vh);
}
</style>
