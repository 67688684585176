<template>
    <div class="row row--15 service-wrapper">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" style="padding-bottom: 30px;!important;"
             v-for="(service, index) in serviceList"
             :key=index>
            <div :class="`card-box ${cardStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="inner">
                    <div class="image">
                        <router-link :to="`/service-details/${service.id}`">
                            <img :src="service.image" alt="card Images"/>
                        </router-link>
                    </div>
                    <div class="content">
                        <h4 class="title mb--20">
                            <router-link :to="`/service-details/${service.id}`" v-html="service.title"/>
                        </h4>
                        <p class="description b1 color-gray mb--0" v-html="service.description"/>
                        <router-link class="btn-default btn-small btn-border" :to="`/service-details/${service.id}`">
                            Read More
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: 'ServiceTwo',
        props: {
            cardStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                {
                        image: require('@/assets/images/service/Service-1.png'),
                        title: 'Website Design',
                        description: 'Crafting responsive, user-centric designs that capture your brand’s essence.',
                        id: 'Web-Design'
                    },
                    {
                        image: require('@/assets/images/service/Service-2.png'),
                        title: 'Business Card',
                        description: 'Innovative NFC cards, merging traditional networking with modern tech.',
                        id: 'BusinessCards'
                    },
                    {
                        image: require('@/assets/images/service/Service-1.png'),
                        title: 'Digital Marketing',
                        description: 'Strategic online business focused campaigns tailored to amplify your brand’s reach.',
                        id: 'Digital Marketing'
                    },
                    {
                        image: require('@/assets/images/service/Service-4.png'),
                        title: 'Other',
                        description: 'Crafting professional standout graphics for posters, emails, and special events.',
                        id: 'Other'
                    }
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>