<template>
  <Layout :topbar-style="2" :footer-style="1">
    <section class="height-750">
      <div class="container">
        <div class="row mb--40">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Login to admin Dashboard"
              title="Login"
              data-aos="fade-up"
              data-aos-delay="60"
            />
          </div>
        </div>
        <form @submit.prevent="login" class="text-left flex">
          <div class="form-group">
            <label for="username">Username</label>
            <input
              type="text"
              class="form-control"
              id="username"
              v-model="username"
              required
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="password"
              required
            />
          </div>
          <div
            class="button-group mt--20"
            data-aos="fade-up"
            data-aos-delay="80"
          >
            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
            <button type="submit" class="btn btn-default">Login</button>
          </div>
        </form>
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import Separator from "../components/elements/separator/Separator";
import Button from "../components/elements/button/Button";
import Icon from "../components/icon/Icon";

export default {
  components: { Icon, Button, Separator, SectionTitle, Layout },
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    login(event) {
      event.preventDefault();
      const validUsername = "admin";
      const validPassword = "password";

      if (this.username === validUsername && this.password === validPassword) {
        this.$router.push("/admin-panel");
      } else {
        this.errorMessage = "Invalid username or password";
      }
    },
  },
};
</script>

<style>
.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
