<template>
    <LayoutAdmin>
      <h2>Create Blog Post</h2>
      <form @submit.prevent="createPost">
        <input v-model="title" placeholder="Title" />
        <textarea v-model="content" placeholder="Content"></textarea>
        <button type="submit">Create Post</button>
      </form>
    </LayoutAdmin>
  </template>
  
  <script>
  import LayoutAdmin from '../common/LayoutAdmin.vue';
  
  export default {
    name: 'CreatePost',
    components: {
      LayoutAdmin
    },
    data() {
      return {
        title: '',
        content: ''
      };
    },
    methods: {
      async createPost() {
        // Send data to an API to create a new post
      }
    }
  };
  </script>
  