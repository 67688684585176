<template>
    <Layout>
        <Breadcrumb current-url="Other Service" :title="title"/>

        <!-- Start Accordion Area -->
        <div class="rn-section-gap">
            <div class="container">
                <div class="row row--40 align-items-center">
                    <div class="col-lg-6">
                        <div class="thumb">
                            <img src="../assets/images/about/about-3.png" alt="Service Images" class="w-100 rounded">
                        </div>
                    </div>
                    <div class="col-lg-6 mt_md--40 mt_sm--40">
                        <div>
                            <p>
                                In the modern digital era, a website stands as the digital storefront for any business, making first impressions.
                            </p>
                            <p>
                                Our web development service ensures that this first impression is elegant, smooth, and serves the purpose. From responsive designs to interactive user interfaces, we craft digital experiences.
                            </p>
                            <h4 class="title">Web Development Essentials</h4>
                            <ul class="liststyle bullet">
                                <li>Customized design tailored for your business</li>
                                <li>Optimized for speed and search engines</li>
                                <li>Dynamic & interactive with a seamless user experience</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->

        <Separator/>

        <!-- Start Accordion Area -->
        <div class="rn-section-gap">
            <div class="container">
                <div class="row row--40 align-items-center">
                    <div class="col-lg-6 order-2 order-lg-1">
                        <div>
                            <p>
                                Each project starts with an in-depth analysis of your business, target audience, and objectives. This allows us to craft a website that resonates with your users.
                            </p>
                            <p>
                                We don't just create websites; we craft digital experiences. Through careful planning, creative design, and modern technologies, our sites stand out in the digital crowd.
                            </p>
                            <p>
                                Partner with us and watch your digital footprint grow, creating more value for your business.
                            </p>
                            <h4 class="title">Our Development Approach</h4>
                            <ul class="liststyle bullet">
                                <li>Understand. Design. Develop. Deploy.</li>
                                <li>Iterative development with continuous feedback</li>
                                <li>Adhering to best practices & modern tech stacks</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 mb_md--40 mb_sm--40 order-1 order-lg-2">
                        <Video :video-data="videoData" column="col-12"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->
    </Layout>
</template>


<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import Separator from '../components/elements/separator/Separator'
import Video from '../components/elements/video/Video'
import Icon from '../components/icon/Icon'

export default {
    name: 'ServiceDetailsPage',
    components: {Icon, Video, Separator, Breadcrumb, Layout},
    props: {
        title: {
            type: String,
            default: 'Other Services'
        }
    },
    data() {
        return {
            videoData: [
                {
                    thumb: require(`@/assets/images/about/contact-image.jpg`),
                    src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
                }
            ]
        }
    }
}
</script>