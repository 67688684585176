<template>
    <div class="countdown">
      <div class="time">
        <span class="number theme-gradient">{{ days }}</span>
        <span class="label">Days</span>
      </div>
      <div class="time">
        <span class="number theme-gradient">{{ hours }}</span>
        <span class="label">Hours</span>
      </div>
      <div class="time">
        <span class="number theme-gradient">{{ minutes }}</span>
        <span class="label">Minutes</span>
      </div>
      <div class="time">
        <span class="number theme-gradient">{{ seconds }}</span>
        <span class="label">Seconds</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        endDate: new Date('2024-01-01 00:00:00'),  // You can set your desired end date here
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    },
    mounted() {
      this.updateCountdown();
      setInterval(this.updateCountdown, 1000);
    },
    methods: {
      updateCountdown() {
        const now = new Date().getTime();
        const distance = this.endDate - now;
  
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }
    }
  };
  </script>
  
  <style scoped>
  .countdown {
    display: flex;
    justify-content: center;
  }
  
  .time {
    margin: 0 10px;
    text-align: center;
  }
  
  .number {
    font-size: 4rem;
    font-weight: bold;
  }
  
  .label {
    font-size: 2rem;
  }
  </style>
  