<template>
    <Layout header-class="header-transparent" :show-newsletter="false" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-850 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/_MyFiles/About-PageImage.jpg`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <div>
                                <h3 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
                                    <span class="theme-gradient">About Us</span>
                                </h3>
                            </div>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                                We are VisionLink <br/> The Ultimate Marketing Agency.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <h3 class="title">Mapping Your Brand's Digital Journey.</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            At VisionLink, we blend innovation with creativity to chart a strategic path for brand success. Explore our detailed timeline below to discover the systematic approach that propels brands into the digital age.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- End Service Area -->

        <Separator/>

        <div class="rwt-timeline-area rn-section-gapBottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData" :timeline-style="3"/>
                    </div>
                </div>
            </div>
        </div>

        <Separator/>

        <AboutFour :image="require(`@/assets/images/about/contact-image.jpg`)"/>

        <!-- Start Elements Area -->
        <div class="rwt-counterup-area pb--100">
            <div class="container mt_dec--30">
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator/>

        <!-- Start Elements Area -->
        <div class="rwt-team-area rn-section-gap">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Our Corporate Team."
                            title="Corporate Team Member."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-xl-4 col-md-12 col-12 mt--30"
                         v-for="(teamMember, index) in teamData"
                         :key="index">
                        <Team :team-member="teamMember" :team-style="2"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout},
        data() {
            return {
                timelineData: 
                [
                    {
                        id: '1',
                        title: 'Discovery',
                        description: 'Understanding your brand needs and digital goals to craft a tailored strategy.',
                    },
                    {
                        id: '2',
                        title: 'Creation',
                        description: 'Designing captivating websites and pioneering NFC business cards tailored to your brand.',
                    },
                    {
                        id: '3',
                        title: 'Marketing',
                        description: 'Leveraging online platforms to enhance your brand’s visibility and engagement.',
                    },
                    {
                        id: '4',
                        title: 'Refinement',
                        description: 'Refining our approach based on analytics, user feedback, and market trends.',
                    },
                ],
                teamData: [
                    {
                        image: 'Jacques-Dark-01',
                        name: 'Jacques van den Berg',
                        designation: 'Co-Founder',
                        location: 'Cape Town, South Africa',
                        description: "As VisionLink's Co-Founder and a dedicated Software Engineering student at Eduvos, I blend my professional tech expertise with a passion for innovative design and brand elevation."
                    },
                    {
                        image: 'Brandon-Dark-01',
                        name: 'Brandon Kriel',
                        designation: 'Co-Founder',
                        location: 'Cape Town, South Africa',
                        description: "As a Co-Founder of VisionLink and an Architectural Technology student, I manage our workflow and design with an adaptive mindset, a keen eye for graphics, and a love for problem-solving."
                    },
                    {
                        image: 'Nickey-Dark-01',
                        name: 'Nickey Spence',
                        designation: 'Digital Marketing Director',
                        location: 'Cape Town, South Africa',
                        description: 'As the Digital Marketing Director, I contribute strong social media proficiency and creative aptitude to elevate VisionLink\'s brand exposure. My commitment to growth fuels my digital leadership.'
                    },
                ]
            }
        }
    }
</script>