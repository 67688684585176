<template>
    <Layout :topbar-style="2" :footer-style="2">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 bg-transparent variation-2 height-750">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                        <div class="inner text-left">
                            <h1 class="title theme-gradient display-two" data-aos="fade-up" data-aos-delay="100">
                                Marketing Agency <br/>For
                                <vue-typed-js class="d-inline-block"
                                              :strings="['Freelancer', 'Business', 'Startups']"
                                              :loop="true"
                                              :type-speed="80"
                                              :start-delay="1000"
                                              :back-speed="50">
                                    <span class="typing"></span>
                                </vue-typed-js>
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="150">
                                VisionLink propels businesses into the digital age with bespoke brand management, innovative NFC business cards, and captivating website designs. Elevate your brand and connect impactfully in today's digital landscape with us.
                            </p>
                            <div class="button-group" data-aos="fade-up" data-aos-delay="200">
                                <router-link class="btn-default btn-medium btn-border round btn-icon" to="/contact">
                                    Contact Us
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 order-1 order-lg-2">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img src="../../assets/images/_MyFiles/Final Mockup png.png" alt="Banner Images"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <Separator/>

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="What Services we Provide"
                            title="Services we provide for you"
                            description="From dynamic websites to innovative NFC cards and digital campaigns, VisionLink elevates your brand."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceTwo
                    service-style="service__style--2 icon-circle-style with-working-process"
                    text-align="center"
                />

            </div>
        </div>
        <!-- End Service Area -->
        <Separator/>
        <!-- Start About Area -->
        <AboutTwo/>
        <!-- End About Area -->
    </Layout>
</template>

<script>
    import Layout from "../../components/common/Layout";
    import Separator from "../../components/elements/separator/Separator";
    import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
    import Icon from "../../components/icon/Icon";
    import ServiceTwo from "../../components/elements/service/ServiceTwo";
    import Brand from "../../components/elements/brand/Brand";
    import AboutTwo from "../../components/elements/about/AboutTwo";

    export default {
        name: 'MarketAgency',
        components: {Brand, ServiceTwo, Icon, SectionTitle, Separator, Layout, AboutTwo},
        data() {
            return {

            }
        }
    }
</script>