<template>
    <Layout :topbar-style="2" :footer-style="1">
        <!-- Start Business Cards Area -->
        <section class="rn-service-area pt--60">
            <div class="rwt-tab-area pt--60">
                <div class="container">
                    <div class="row mb--40">
                        <div class="col-lg-12">
                            <SectionTitle
                                text-align="center"
                                subtitle="All Bundled in one place"
                                title="Business Card Services"
                                data-aos="fade-up"
                                data-aos-delay="60"
                            />
                        </div>
                    </div>
                    <Tab :tab-data="tabData"/>
                    <router-link class="btn-default btn-group btn-icon mt--20 mb--30 d-table mx-auto"
                                   to="/services/BusinessCards">
                                    Find Out More!
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                    </router-link>
                </div>
            </div>
        </section>
        <!-- End Business Cards Area -->

        <Separator/>

        <!-- Start Graphic Designs Service Area -->
        <section class="rn-service-area rn-section-gap">
            <div class="rwt-tab-area ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <SectionTitle
                                text-align="center"
                                subtitle="Custom Creativity, Tailored for You"
                                title="Graphic Design Services"
                                data-aos="fade-up"
                                data-aos-delay="60"
                            />
                        </div>
                    </div>
                    <div class="container">
                            <div class="row row--40 align-items-center">
                                <div class="col-lg-6 order-2 order-lg-1">
                                    <div>
                                    <p>
                                        Our Graphic Design Services go beyond just aesthetics. We create custom designs that resonate with your brand's personality and message.
                                    </p>
                                    <p>
                                        Every piece we create, from logos to marketing materials, is tailored to showcase your brand's unique style and make it stand out from the competition.
                                    </p>
                                    <p>
                                        Experience a visual transformation that elevates your brand and captivates your audience with our expert design services.
                                    </p>
                                    <h4 class="title">Bespoke Design Solutions</h4>
                                    <ul class="liststyle bullet">
                                        <li>Custom designs for your brand</li>
                                        <li>Captivating visual elements</li>
                                        <li>High-quality, professional output</li>
                                    </ul>
                                </div>

                                </div>
                                <div class="col-lg-6 mb_md--40 mb_sm--40 order-1 order-lg-2">
                                    <Video :video-data="videoData" column="col-12"/>
                                </div>
                            </div>
                        </div>
                        <router-link class="btn-default btn-group btn-icon mt--20 mb--30 d-table mx-auto"
                                   to="/services/Other">
                                    Find Out More!
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                    </router-link>
                </div>
            </div>
        </section>
        <!-- End Graphic Designs Service Area -->

        <Separator/>

        <!-- Start Service Area -->
        <section class="rn-service-area rn-section-gap">

        </section>
        <!-- End Service Area -->

        <Separator/>


    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Tab from '../components/elements/tab/Tab'
    import Button from '../components/elements/button/Button'
    import Video from '../components/elements/video/Video'

    export default {
        name: 'ServicePage',
        components: {Tab, Button, Video, SectionTitle, Separator, Layout, Breadcrumb},
        data() {
            return {
                videoData: [
                    {
                        thumb: require(`@/assets/images/_MyFiles/Other-Services-VideoThumbnail.jpg`),
                        src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
                    }
                ],
                tabData: {
                    tabImage: 'tab-test0.jpg',
                    tabContent: [
                        {       
                            id: 1,
                            menu: 'Complete-Bundle',
                            image: 'tab-test0.jpg',
                            content: `<p>Experience the full power of our business card offerings with the Complete Bundle. This package brings together our high-quality traditional business cards, interactive virtual cards, and innovative smart business cards with NFC technology, all customized to your brand's unique style. By combining the best of physical and digital networking, this all-inclusive solution ensures that you're ready to connect with anyone, anywhere.</p>`
                        },
                        {
                            id: 2,
                            menu: 'Traditional',
                            image: 'tab-test.jpg',
                            content: `<p>Make a lasting first impression with our professionally designed, high-quality traditional business cards that reflect your brand's personality. Our cards are tailored to your brand's unique style, ensuring that you leave a memorable impression on potential clients and partners.</p>`
                        },
                        {
                            id: 3,
                            menu: 'Virtual',
                            image: 'tab-test2.jpg',
                            content: `<p>Navigate the digital landscape effortlessly with virtual business cards that offer interactive content and easy sharing through online platforms or as downloadable PDFs. Whether you're networking online or sharing your details in a meeting, our virtual business cards help you connect with ease.</p>`
                        },
                        {
                            id: 4,
                            menu: "Smart",
                            image: 'tab-test3.jpg',
                            content: `<p>Go beyond the ordinary with smart business cards that enable instant sharing of your digital presence through NFC technology with just a tap. Empower your brand with this innovative solution and experience the future of networking, today.</p>`
                        }
                    ]
                },
            }
        }
    }
</script>