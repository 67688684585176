<template>
    <LayoutAdmin>
      <h2>Manage Users</h2>
      <ul>
        <li v-for="user in users" :key="user.id">
          {{ user.username }} - <button @click="deleteUser(user.id)">Delete</button>
        </li>
      </ul>
    </LayoutAdmin>
  </template>
  
  <script>
  import LayoutAdmin from '../common/LayoutAdmin.vue';
  
  export default {
    name: 'ManageUsers',
    components: {
      LayoutAdmin
    },
    data() {
      return {
        users: []
      };
    },
    async created() {
      // Fetch users from an API or database here
    },
    methods: {
      async deleteUser(userId) {
        // Delete user by sending a request to an API
      }
    }
  };
  </script>
  