<template>
    <Layout>
        <!-- Start Accordion Area -->
        <div class="rn-section-gap">
            <div class="container">
                <div class="row row--40 align-items-center">
                    <div class="col-lg-6">
                        <div class="thumb">
                            <img src="../assets/images/about/about-3.png" alt="Service Images" class="w-100 rounded">
                        </div>
                    </div>
                    <div class="col-lg-6 mt_md--40 mt_sm--40">
                        <div>
                            <p>
                                Business cards remain an indispensable tool for businesses even in the digital age. They act as an identity, reflecting the brand's essence and value.
                            </p>
                            <p>
                                Our NFC-enabled business cards are not just a way to share your contact information but also a medium to connect and engage with clients in an interactive manner.
                            </p>
                            <h4 class="title">Card Features</h4>
                            <ul class="liststyle bullet">
                                <li>High-quality print with vibrant color reproduction</li>
                                <li>NFC-enabled for smart interactions</li>
                                <li>Custom designs tailored to your brand</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->

        <Separator/>

        <!-- Start Accordion Area -->
        <div class="rn-section-gap">
            <div class="container">
                <div class="row row--40 align-items-center">
                    <div class="col-lg-6 order-2 order-lg-1">
                        <div>
                            <p>
                                The revolution of business cards has just begun. With the combination of traditional design and modern technology, our cards ensure you leave a lasting impression.
                            </p>
                            <p>
                                From the first draft to the final product, our team ensures every card is a piece of art, resonating with your brand's identity.
                            </p>
                            <p>
                                Come, transform the way you network with our innovative business cards.
                            </p>
                            <h4 class="title">Creating Impressions</h4>
                            <ul class="liststyle bullet">
                                <li>Design that speaks your brand</li>
                                <li>Smart technology integrations</li>
                                <li>End-to-end customization</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 mb_md--40 mb_sm--40 order-1 order-lg-2">
                        <Video :video-data="videoData" column="col-12"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->
    </Layout>
</template>


<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import Separator from '../components/elements/separator/Separator'
import Video from '../components/elements/video/Video'
import Icon from '../components/icon/Icon'

export default {
    name: 'ServiceDetailsPage',
    components: {Icon, Video, Separator, Breadcrumb, Layout},
    props: {
        title: {
            type: String,
            default: 'Business Cards'
        }
    },
    data() {
        return {
            videoData: [
                {
                    thumb: require(`@/assets/images/about/contact-image.jpg`),
                    src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
                }
            ]
        }
    }
}
</script>