import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/About";
import ButtonPage from "../views/ButtonPage";
import ServicePage from "../views/ServicePage";
import ServiceBusinessCards from "../views/Service-BusinessCards.vue";
import ServiceOther from "../views/Service-Other.vue";
import ServiceDetailsPage from "../views/ServiceDetailsPage";
import CounterUpPage from "../views/CounterUpPage";
import ProgressbarPage from "../views/ProgressbarPage";
import AccordionPage from "../views/AccordionPage";
import SocialSharePage from "../views/SocialSharePage";
import BlogGridPage from "../views/BlogGridPage";
import BlogListPage from "../views/BlogListPage";
import BlogDetailsPage from "../views/BlogDetailsPage";
import BlogCategoryPosts from "../components/blog/BlogCategoryPosts";
import TeamPage from "../views/TeamPage";
import TimelinePage from "../views/TimelinePage";
import TabPage from "../views/TabPage";
import PricingPage from "../views/PricingPage";
import SplitSectionPage from "../views/SplitSectionPage";
import CallToActionPage from "../views/CallToActionPage";
import VideoPage from "../views/VideoPage";
import ContactPage from "../views/ContactPage";
import ErrorPage from "../views/404";
import AdvanceTabPage from "../views/AdvanceTabPage";
import AdvancePricingPage from "../views/AdvancePricingPage";
import MarketAgency from "../views/home-pages/MarketAgency";
// import Maintenance from '../views/Maintenance.vue'
import DynamicServiceDetails from "../views/DynamicServiceDetails";
import ServicesHome from "../views/Services";
import LoginAdmin from "../views/Login";
import AdminDashboard from "../views/AdminDashboard";
import AdminDashboardComp from '../components/admin/AdminDashboard'
import CreatePost from '../components/admin/CreatePost'
import ManageUsers from '../components/admin/Manage'
import ViewAnalytics from '../components/admin/ViewAnalytics'

Vue.use(VueRouter);

const routes = [
  {
    path: "/button",
    name: "Button",
    component: ButtonPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/service",
    name: "Service",
    component: ServicePage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/service-businesscards",
    name: "Service Details Business Cards",
    component: ServiceBusinessCards,
    meta: {
      title: "VisionLink || Business Cards",
    },
  },
  {
    path: "/service-other",
    name: "Service Details Other",
    component: ServiceOther,
    meta: {
      title: "VisionLink || Other Services",
    },
  },
  {
    path: "/service-details",
    name: "Service Details",
    component: ServiceDetailsPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/services",
    name: "ServicesHome",
    component: ServicesHome,
    meta: {
      title: "VisionLink || Services",
    },
    props: true,
  },
  {
    path: "/services/:service",
    name: "ServiceDetails",
    component: DynamicServiceDetails,
    props: true,
  },
  {
    path: "/counter",
    name: "Counter",
    component: CounterUpPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/progressbar",
    name: "Progressbar",
    component: ProgressbarPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/accordion",
    name: "Accordion",
    component: AccordionPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/social-share",
    name: "Social share",
    component: SocialSharePage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/blog",
    name: "Blog grid",
    component: BlogGridPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/blog-list",
    name: "Blog list",
    component: BlogListPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/blog-details/:id",
    name: "Blog details",
    component: BlogDetailsPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/category/:slug",
    name: "Blog Category List",
    component: BlogCategoryPosts,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/team",
    name: "Team",
    component: TeamPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/timeline",
    name: "Timeline",
    component: TimelinePage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/tab",
    name: "Tab",
    component: TabPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: PricingPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/spit-section",
    name: "Split section",
    component: SplitSectionPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/call-to-action",
    name: "Call to action",
    component: CallToActionPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/video",
    name: "Video",
    component: VideoPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/advance-tab",
    name: "Advance tab",
    component: AdvanceTabPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/advance-pricing",
    name: "Advance pricing",
    component: AdvancePricingPage,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/",
    name: "Marketing Agency",
    component: MarketAgency,
    meta: {
      title: "VisionLink || Unlock your Potential",
    },
  },
  {
    path: "/admin-panel",
    name: "Admin Dashboard",
    component: AdminDashboardComp,
    meta: {
      title: "VisionLink || Admin Dashboard",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginAdmin,
    meta: {
      title: "VisionLink || Admin Dashboard",
    },
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboardComp,
    meta: {
        title: "VisionLink || Admin Dashboard",
      },
  },
  {
    path: "/admin/create-post",
    name: "CreatePost",
    component: CreatePost,
    meta: {
        title: "VisionLink || Create Post",
      },
  },
  {
    path: "/admin/manage-users",
    name: "ManageUsers",
    component: ManageUsers,
    meta: {
        title: "VisionLink || Manage Users",
      },
  },
  {
    path: "/admin/view-analytics",
    name: "ViewAnalytics",
    component: ViewAnalytics,
    meta: {
        title: "VisionLink || View Analytics",
      },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0);
});

export default router;
