<template>
    <div class="w-75 p-4">
        <router-view></router-view>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdminDashboard'
  }
  </script>
  
  <style>
  .nav-link:hover {
    color: #17a2b8 !important;
  }
  </style>
  