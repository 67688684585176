<template>
    <Layout :topbar-style="2" :footer-style="1">

        <!-- Start Coming Soon Area -->
        <div class="rn-section-gap coming-soon-area bg-transparent height-750">
            <div class="container text-center">

                <!-- Title and Description -->
                <h1 class="title theme-gradient display-two" data-aos="fade-up" data-aos-delay="100">Coming Soon!</h1>
                <p class="description" data-aos="fade-up" data-aos-delay="100">
                    We're working hard to bring you our exciting new services. Stay tuned for updates!
                </p>
                <div class="countdown-timer">
                    <div class="">
                    </div>
                    <ServiceTwo
                    service-style="service__style--2 icon-circle-style with-working-process"
                    text-align="center"
                    />
                </div>
                
            </div>
            
        </div>
        
        <!-- End Coming Soon Area -->

    </Layout>
</template>

<style scoped>

</style>

<script>
    import Layout from "../components/common/Layout";
    import Separator from "../components/elements/separator/Separator";
    import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
    import Icon from "../components/icon/Icon";
    import countdown from "../components/elements/countdown/countdown";
    import ServiceTwo from "../components/elements/service/ServiceTwo";

export default {
    name: 'ComingSoon',
    components: {countdown, Layout, Separator, SectionTitle, Icon, ServiceTwo},
    data() {
        return {

        };
    },

}
</script>
