<template>
    <LayoutAdmin>
      <h2 class="text-center color-blue-gradient">Analytics</h2>
    <div class="row">
      <div class="col-lg-4">
        <div class="card bg-translucent text-white">
          <div class="card-body">
            <h5 class="card-title">Total Posts</h5>
            <p class="card-text">{{ totalPosts }}</p>
            <router-link to="/admin/create-post" class="btn btn-primary">
              Create Post
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card bg-translucent text-white">
          <div class="card-body">
            <h5 class="card-title">Total Users</h5>
            <p class="card-text">{{ totalUsers }}</p>
            <router-link to="/admin/manage-users" class="btn btn-primary">
              Manage Users
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card bg-translucent text-white">
          <div class="card-body">
            <h5 class="card-title">Total Users</h5>
            <p class="card-text">{{ totalUsers }}</p>
            <router-link to="/admin/manage-users" class="btn btn-primary">
              Manage Users
            </router-link>
          </div>
        </div>
      </div>
    </div>
    </LayoutAdmin>
  </template>
  
  <script>
  import LayoutAdmin from '../common/LayoutAdmin.vue';
  
  export default {
    name: 'ViewAnalytics',
    components: {
      LayoutAdmin
    },
    async created() {
      // Fetch analytics data from an API or database here
    }
  };
  </script>
  