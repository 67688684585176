<template>
    <ul class="mainmenu">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">About</router-link></li>
        <li class="has-droupdown has-menu-child-item">
            <a href="#">
                Services
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
            <ul class="submenu">
                <li><router-link to="/services">All Services</router-link></li>
                <li><router-link to="/services/Web-Design">Web-Design &nbsp;<span class="rn-badge-card">Soon</span></router-link></li>
                <li><router-link to="/services/BusinessCards">BusinessCards</router-link></li>
                <li><router-link to="/services/Digital Marketing">Digital Marketing &nbsp;<span class="rn-badge-card">Soon</span></router-link></li>
                <li><router-link to="/services/Other">Other</router-link></li>
            </ul>
        </li>
        <!-- <li><router-link to="/blog">Blog</router-link></li> -->
        <li><router-link to="/contact">Contact</router-link></li>
    </ul>


</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>

<style>
    .mainmenu .submenu {
        display: none;
    }
    .mainmenu li:hover .submenu {
        display: block;
    }
</style>
