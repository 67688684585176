<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26514.550312478794!2d18.640706202189644!3d-33.82988853758942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc571a33692941%3A0x3426a4a9413bb53!2sDurbanville%2C%20Cape%20Town!5e0!3m2!1sen!2sza!4v1691870111272!5m2!1sen!2sza" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <!-- End Google MAp -->
</template>

<script>
    export default {
        name: 'GoogleMap'
    }
</script>